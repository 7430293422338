@font-face {
	font-family: 'LabilGrotesk-Bold';
	src: url('/public/assets/Font/LabilGrotesk-Bold.woff2') format('woff2'),
		url('/public/assets/Font/LabilGrotesk-Bold.woff') format('woff'),
		url('/public/assets/Font/LabilGrotesk-Bold.ttf') format('truetype'),
		url('/public/assets/Font/LabilGrotesk-Bold.eot'),
		url('/public/assets/Font/LabilGrotesk-Bold.svg') format('svg');
	font-style: normal;
	font-weight: normal;
	font-display: swap;
}

@font-face {
	font-family: 'LabilGrotesk-Black';
	src: url('/public/assets/Font/LabilGrotesk-Black.woff2') format('woff2'),
		url('/public/assets/Font/LabilGrotesk-Black.woff') format('woff'),
		url('/public/assets/Font/LabilGrotesk-Black.ttf') format('truetype'),
		url('/public/assets/Font/LabilGrotesk-Black.eot'),
		url('/public/assets/Font/LabilGrotesk-Black.svg') format('svg');
	font-style: normal;
	font-weight: normal;
	font-display: swap;
}

@font-face {
	font-family: 'LabilRecfindr-Black';
	src: url('/public/assets/Font/LabilRecfindr-Black.woff2') format('woff2'),
		url('/public/assets/Font/LabilRecfindr-Black.woff') format('woff'),
		url('/public/assets/Font/LabilRecfindr-Black.ttf') format('truetype'),
		url('/public/assets/Font/LabilRecfindr-Black.eot'),
		url('/public/assets/Font/LabilRecfindr-Black.svg') format('svg');
	font-style: normal;
	font-weight: normal;
	font-display: swap;
}

@font-face {
	font-family: 'LabilGrotesk-Light';
	src: url('/public/assets/Font/LabilGrotesk-Light.woff2') format('woff2'),
		url('/public/assets/Font/LabilGrotesk-Light.woff') format('woff'),
		url('/public/assets/Font/LabilGrotesk-Light.ttf') format('truetype'),
		url('/public/assets/Font/LabilGrotesk-Light.eot'),
		url('/public/assets/Font/LabilGrotesk-Light.svg') format('svg');
	font-style: normal;
	font-weight: normal;
	font-display: swap;
}

:root {
	--theme-one: #00b1b0;
	--theme-two: #e42256;
	--theme-three: #1c1c1c;
	--theme-four: #fac54c;
	--theme-five: #fff;
	--theme-six: #f48371;
}

html {
	font-family: 'LabilGrotesk-Black', sans-serif;
	-webkit-font-smoothing: antialiased;
}

form {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	flex-grow: 1;
	padding-bottom: 1rem;
}

.offscreen {
	position: absolute;
	left: -9999px;
}

.hide {
	display: none;
}

/* .valid {
    color: limegreen;
    margin-left: 0.25rem;
}

.invalid {
    color: red;
    margin-left: 0.25rem;
} */

.line {
	display: inline-block;
}

.flexGrow {
	flex-grow: 1;
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
}

.lds-facebook {
	display: block;
	margin: 0 auto;
	position: relative;
	width: 160px;
	height: 160px;
}
.lds-facebook div {
	position: absolute;
	border: 4px solid #00b1af;
	opacity: 1;
	border-radius: 50%;
	animation: lds-facebook 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-facebook div:nth-child(2) {
	animation-delay: -0.5s;
}

.lds-facebook div:nth-child(1) {
	background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 27.8.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='114.9px' height='114.9px' viewBox='0 0 114.9 114.9' style='enable-background:new 0 0 114.9 114.9;' xml:space='preserve' %3E%3Cstyle type='text/css'%3E .st0%7Bfill:%231C1C1C;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M6.2,18.2l32.6-7l3.5,21.1c0.6-20,13.7-28.5,29.1-28l7,32.6c-15.2,0.4-32.6,6.5-28,28.7l8,38l-32.6,7L6.2,18.2 z'/%3E%3Cpath class='st0' d='M69.7,88.1c0-10.9,8.5-19.4,19.5-19.4c10.9,0,19.5,8.5,19.5,19.4s-8.5,19.5-19.5,19.5 C78.2,107.5,69.7,99,69.7,88.1z'/%3E%3C/g%3E%3C/svg%3E%0A");
	background-size: contain;
	background-repeat: no-repeat;
}

@keyframes lds-facebook {
	0% {
		top: 62px;
		left: 62px;
		width: 0;
		height: 0;
		opacity: 0;
	}
	4.9% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 0;
	}
	5% {
		top: 62px;
		left: 62px;
		width: 0;
		height: 0;
		opacity: 1;
	}
	100% {
		top: 0px;
		left: 0px;
		width: 124px;
		height: 124px;
		opacity: 0;
	}
}

.editProfile input[type='text'],
.editProfile input[type='password'],
.editProfile input[type='submit'],
.editProfile input,
.editProfile button,
.editProfile textarea {
	font-family: 'Nunito', sans-serif;
	font-size: 22px;
	padding: 0.35rem;
	color: var(--theme-three);
	margin: 0 auto;
	margin-top: 5px;
}

.editProfile button {
	background: var(--theme-two);
	color: var(--theme-three);
}

.reviewTile a {
	color: #000;
	text-decoration: none;
}

.stackContainer {
	width: 90vw;
	margin: 0 auto;
}

.stackContainer {
	height: 360px;
	max-width: 260px;
}
.stackItem {
	position: absolute !important;
	width: 260px;
	height: 360px;
}
